<template>
  <div class="step-wrapper" :class="{ activeStep: active }">
    <button
      class="bg-[#58C1E1] rounded-[100px] py-3 px-12 text-white text-sm font-bold"
      :disabled="firststep"
      :style="[firststep ? { opacity: '0' } : { opacity: '1' }]"
      style="font-family: var(--sans) !important"
      @click="stepCustom ? lastStepCustom() : lastStep()"
    >
      Back
    </button>
    <button
      v-if="!laststep"
      class="bg-[#58C1E1] rounded-[100px] py-3 px-12 text-white text-sm font-bold"
      :disabled="calendar || missionDetails"
      style="font-family: var(--sans) !important"
      @click="stepCustom ? nextStepCustom() : nextStep()"
    >
      Next
    </button>
    <button
      v-if="laststep"
      class="bg-[#58C1E1] rounded-[100px] py-3 px-12 text-white text-sm font-bold"
      :disabled="finishStepStatus"
      style="font-family: var(--sans) !important"
      @click="submitStep()"
    >
      Finish
    </button>
  </div>
</template>

<script>
  export default {
    props: [
      "step",
      "stepcount",
      "currentstep",
      "calendar",
      "missionDetails",
      "finishStepStatus",
      "selectedDates",
      "stepCustom",
    ],

    computed: {
      active: function () {
        return this.step.id == this.currentstep;
      },

      firststep: function () {
        return this.currentstep == 1;
      },

      laststep: function () {
        return this.currentstep == this.stepcount;
      },
    },

    methods: {
      nextStep: function () {
        this.$emit("step-change", this.currentstep + 1);
      },
      lastStep: function () {
        this.$emit("step-change", this.currentstep - 1);
      },
      nextStepCustom: function () {
        if (this.currentstep == 2) {
          if (Object.keys(this.selectedDates).length > 0) {
            if (this.selectedDates.selectedDates.length > 1) {
              this.$emit("step-change", this.currentstep + 2);
            } else {
              this.$emit("step-change", this.currentstep + 1);
            }
          }
        } else {
          this.$emit("step-change", this.currentstep + 1);
        }
      },

      lastStepCustom: function () {
        if (this.currentstep == 4) {
          if (Object.keys(this.selectedDates).length > 0) {
            if (this.selectedDates.selectedDates.length > 1) {
              this.$emit("step-change", this.currentstep - 2);
            } else {
              this.$emit("step-change", this.currentstep - 1);
            }
          }
        } else {
          this.$emit("step-change", this.currentstep - 1);
        }
      },

      submitStep: function () {
        this.$emit("step-submit", this.currentstep + 1);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .saveAddressButton {
    background: var(--red);
    border: none;
    width: 100%;
    color: white;
    padding: 15px;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 700;
    margin-top: 20px;
    cursor: pointer;
  }
  .step-wrapper {
    margin-top: 30px;
  }
</style>
